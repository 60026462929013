export const imports = {
  'src/components.mdx': () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "src-components" */ 'src/components.mdx'
    ),
  'src/advanced-usage.mdx': () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "src-advanced-usage" */ 'src/advanced-usage.mdx'
    ),
  'src/getting-started.mdx': () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "src-getting-started" */ 'src/getting-started.mdx'
    ),
  'src/index.mdx': () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "src-index" */ 'src/index.mdx'
    ),
  'src/recipes.mdx': () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "src-recipes" */ 'src/recipes.mdx'
    ),
}
